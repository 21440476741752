import { Pagination } from '@oresundsbron/design-system';
import { ArrowUp } from '@oresundsbron/icons';
import { CorporateGroupCustomer } from '@oresundsbron/validators';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCorporateGroup } from '~/modules/account/CorporateGroup/useCorporateGroup';
import { useUpdateSessionCustomer } from '~/hooks/useUpdateSessionCustomer';
import { Button } from '@oresundsbron/bridge-ui';

import { Loading } from '~/components/Account/layouts/Loading';
import { CorporateGroupListItem } from './CorporateGroupListItem';
import FilterInput, {
  MultiSelectHandle,
} from '~/components/Inputs/Filter/MultiSelect';
import { useCustomerInformation } from '~/hooks/useCustomerInformation';
import { SelectedCompany } from './SelectedCompany';
import {
  SearchInput,
  LoadingScreen,
  UserFeedback,
} from '@oresundsbron/app-common';

const pageSize = 10;
const corporateFilterFields: (keyof CorporateGroupCustomer)[] = [
  'name',
  'customerNo',
];

type CoporateGroupProps = {
  onSuccess?: () => void;
};

export const CorporateGroup = ({ onSuccess }: CoporateGroupProps) => {
  const [page, setPage] = useState(1);

  const { t } = useTranslation(['common', 'account'], { nsMode: 'fallback' });

  const [searchString, setSearchString] = useState('');
  const [languageCode, setLanguageCode] = useState<string[]>([]);
  const [errorIndex, setErrorIndex] = useState<number | undefined>(undefined);
  const [loadingIndex, setLoadingIndex] = useState<number | undefined>(
    undefined
  );
  const { corporateGroup, corporateHead, isLoading } = useCorporateGroup();
  const { customerInformation } = useCustomerInformation();

  const filteredCorporateGroup = useMemo(() => {
    let returnData = corporateGroup.filter(
      (corporate) =>
        (searchString.length < 3 ||
          corporateFilterFields.some((field) =>
            corporate[field]
              ?.toString()
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )) &&
        (languageCode.length === 0 ||
          languageCode.includes(corporate.languageCode))
    );

    const sortFirst =
      customerInformation?.customerType === 'business' &&
      customerInformation?.customerNo;

    if (sortFirst) {
      returnData = returnData.sort((a, b) =>
        a.customerNo === sortFirst ? -1 : b.customerNo === sortFirst ? 1 : 0
      );
    }

    return returnData;
  }, [customerInformation, corporateGroup, searchString, languageCode]);

  useEffect(() => {
    setPage(1);
  }, [searchString, languageCode]);

  const totalPages = useMemo(
    () => Math.ceil(filteredCorporateGroup.length / pageSize),
    [filteredCorporateGroup]
  );

  const paginatedCorporateGroup = useMemo(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredCorporateGroup.slice(startIndex, endIndex);
  }, [filteredCorporateGroup, page]);

  const countryOptions = useMemo(() => {
    const languageCodes = corporateGroup.map(
      (corporate) => corporate.languageCode
    );
    const uniqueLanguageCodes = Array.from(new Set(languageCodes));
    return uniqueLanguageCodes.map((code) => ({ value: code, label: code }));
  }, [corporateGroup]);

  const updateSessionCustomer = useUpdateSessionCustomer();

  const handleUpdateSessionCustomer = async (
    customerNo: string,
    index: number
  ) => {
    setLoadingIndex(index);
    setErrorIndex(undefined);
    const res = await updateSessionCustomer(customerNo);
    if (res && 'error' in res) {
      setLoadingIndex(undefined);
      setErrorIndex(index);
      setTimeout(() => {
        setErrorIndex(undefined);
      }, 4000);
    } else {
      onSuccess?.();
    }
  };
  const countrySelectRef = useRef<MultiSelectHandle>(null);

  const clearSearch = () => {
    countrySelectRef.current?.resetSelection();
    setSearchString('');
  };

  if (isLoading) return <Loading />;

  const activeCustomerNo =
    customerInformation?.customerType === 'business'
      ? customerInformation.customerNo
      : '';

  return (
    <>
      <div className="relative">
        {corporateGroup.length > 0 && (
          <div className="mx-auto max-w-2xl">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-semibold">
                {`${t('corporateGroup.header')} (${
                  filteredCorporateGroup.length
                })`}
              </h1>
              <h1 className="text-base font-semibold"></h1>
            </div>
            <div className="my-8 flex min-w-full flex-1 flex-col items-end gap-5 rounded-lg bg-neutral-50 p-4 md:flex-row">
              <SearchInput
                label={t('corporateGroup.filter.search')}
                query={searchString}
                setQuery={setSearchString}
                className="flex-grow"
              />
              <FilterInput
                className="flex-grow"
                options={countryOptions}
                label={t('corporateGroup.filter.country')}
                onChange={setLanguageCode}
                ref={countrySelectRef}
              />
              <Button onClick={clearSearch}>
                {t('corporateGroup.filter.clear')}
              </Button>
            </div>
            {corporateHead && (
              <>
                <div className="font-bold">
                  {t('corporateGroup.mainCompany')}
                </div>
                <div
                  className="mt-2 flex max-h-min items-center border-[0.5px] border-neutral-300 p-4 text-sm font-semibold hover:cursor-pointer hover:bg-neutral-50"
                  onClick={async () => {
                    setLoadingIndex(1000);
                    await updateSessionCustomer(corporateHead.customerNo);
                    onSuccess?.();
                  }}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      {activeCustomerNo === corporateHead?.customerNo && (
                        <SelectedCompany />
                      )}{' '}
                      {corporateHead?.name}, {corporateHead?.customerNo}
                    </div>
                    <ArrowUp className="rotate-90 text-primary-700" />
                  </div>
                </div>
              </>
            )}
            <div
              className={`${
                totalPages === 1 ? 'mb-2' : ''
              } mt-8 flex flex-col gap-2`}
            >
              <div className="font-bold">
                {t('corporateGroup.corporateGroupCompanies')}
              </div>
              {paginatedCorporateGroup.length < 1 ? (
                <UserFeedback colorMode="information">
                  {t('corporateGroup.emptySearch')}
                </UserFeedback>
              ) : (
                paginatedCorporateGroup.map((corporate, index) => (
                  <CorporateGroupListItem
                    activeCustomerNo={activeCustomerNo}
                    key={corporate.customerNo}
                    corporate={corporate}
                    handleUpdateSessionCustomer={handleUpdateSessionCustomer}
                    index={index}
                    errorIndex={errorIndex}
                    isLoading={index === loadingIndex}
                  />
                ))
              )}
            </div>
            {totalPages > 1 && (
              <Pagination
                className="mt-8"
                currentPage={page}
                totalPages={totalPages}
                labels={{
                  next: t('action.next'),
                  previous: t('action.previous'),
                }}
                onPageChange={setPage}
              />
            )}
          </div>
        )}
        {loadingIndex !== undefined && <LoadingScreen />}
      </div>
    </>
  );
};
